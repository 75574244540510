import React from 'react';
import { graphql } from 'gatsby';

// Components
import { FilterBar } from 'components';

// Layouts
import { PageLayout, SEOContent } from 'layouts';

// Templates
import { BasicTemplate, CurrentMembers } from 'templates';

// Types
import { CurrentMember } from 'types';
interface Props {
  data: {
    members: CurrentMember;
  };
}

const FILTERS = [{ title: 'Organization', gqlKey: 'organization' }];

const CurrentMembersPageTemplate = (props: Props) => {
  const { members } = props.data;

  return (
    <SEOContent>
      <PageLayout>
        <BasicTemplate
          templateTitle="Current members"
          subTitles={[`Active members (${members.totalCount})`]}
        >
          <FilterBar
            data={members}
            filters={FILTERS}
            render={(tableData) => <CurrentMembers data={tableData} />}
          />
        </BasicTemplate>
      </PageLayout>
    </SEOContent>
  );
};

export default CurrentMembersPageTemplate;

export const query = graphql`
  query {
    members: allAirtable(
      filter: { table: { eq: "Members" }, data: { status: { ne: "Inactive" } } }
      sort: { order: ASC, fields: data___last_name }
    ) {
      nodes {
        data {
          full_name
          last_name
          first_name
          member_slug
          payment_date(formatString: "MM-DD-YYYY")
          profile_picture {
            thumbnails {
              large {
                url
              }
            }
          }
          organization
          link: member_slug
          is_author
        }
      }
      totalCount
    }
  }
`;
